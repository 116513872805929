// export const FRONT_URL = "http://localhost:3000";
export const FRONT_URL = "https://i5.espochic.com/";
// export const FRONT_URL = "https://dics.espochic.com/";
// export const FRONT_URL = "http://192.168.1.26/";
// export const FRONT_URL = "http://192.168.100.100/";
// export const API_URL = "http://localhost:1337";
export const API_URL = "https://i5.espochic.com/api";
// export const API_URL = "https://dics.espochic.com/api";
// export const API_URL = "http://192.168.1.26:1337";
// export const API_URL = "http://192.168.\100.100/api";
// export const SOCKET_URL = "http://localhost:1337";
export const SOCKET_URL = "https://i5.espochic.com";
// export const SOCKET_URL = "https://dics.espochic.com";
// export const SOCKET_URL = "http://192.168.1.26";
// export const SOCKET_URL = "http://192.168.100.100";
export const TOKEN_KEY = "strapi-jwt-token";
export const VERSION = "2.3.0"
export const TOKENSTRAPI = '503d58146c188ee701317ea80aed09d7c9bbbc70279ed7237e38b91002bbf2d095685c4ff16141471bf796fda650964c26cbe220dedad29589df4945d7bab7e7e83500287546be6fcaa2d6b3f9d3e626b1fd6355347b244ad330cf339f825e117cbd4146d6eee8dfda9abfec9168921aa9bd167b7c09ca7a2d4fc4f9b2908bbe';
