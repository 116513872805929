import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import {Checkbox, Col, Form, Input, Row, Select, Switch} from 'antd';
import React, {useContext} from 'react';
import {UtilityHelper} from '../../helper/utility';
import {API_URL} from '../../constants';
import {StoreContext} from '../../contexts/StoreContext';
import { useNavigate } from "react-router-dom";
import {IEspositori, IMagazzini, ISeller} from '../../interfaces';
import {MagazziniList} from './list';


export const MagazziniCreate: React.FC<IResourceComponentsProps> = () => {
    const utilityHelper = UtilityHelper(API_URL + "/api");
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const navigate = useNavigate();
    const {formProps, saveButtonProps} = useForm<IMagazzini>({
        warnWhenUnsavedChanges: false
    });

    return (
            <Create saveButtonProps={saveButtonProps}>
                <Form
                    {...formProps}
                    layout="vertical"
                >
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Create>
    );
};
