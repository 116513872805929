import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React, {useContext} from 'react';
import {IDocType, IEspositori, IMagazzini} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';

export const MagazziniList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const {tableProps} = useTable<IMagazzini>({
        resource: "magazzinis",
        meta: {populate: '*'},
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IDocType>();

    return (
            <List
                title={translate('pages.docType.magazzini', 'Magazzini')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        // <ExportButton
                        //     onClick={triggerExport}
                        //     loading={exportLoading}
                        // />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("docType", record);
                               },
                           };
                       }}
                       sticky={true}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.docType.description', 'Descrizione')}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.docType.action', 'Azioni')}
                        dataIndex="actions"
                        key="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
