import {IResourceComponentsProps, useExport, useTranslate} from '@refinedev/core';

import {
    DateField,
    List, useSelect,
    useTable,
} from '@refinedev/antd';

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";

import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography} from 'antd';
import React, {useContext, useEffect, useRef, useState} from 'react';
import {StoreContext} from '../../contexts/StoreContext';
import {IStock} from '../../interfaces';
import dayjs from 'dayjs';
import * as Icons from '@ant-design/icons';
import {CSVLink} from 'react-csv';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
const { Title } = Typography;

export const ReportTurniList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {value, value2, value4} = useContext(StoreContext);
    const [store, setStore] = value;
    const [espositore, setEspositore] = value4;
    const [datiGev, setDatiGev] = useState<any>([]);


    const { tableProps, tableQueryResult, searchFormProps} = useTable<any>({
        resource: "custom/getTurni",
        syncWithLocation: false,
        queryOptions: {
            enabled: true
        },
        onSearch: (values: any) => {
            const dIn = new Date(values.dataIn);
            const dOut = new Date(values.dataOut);
            dIn.setHours(0,0,0,0);
            dOut.setHours(23,59,59,0);
            const dataIn = new Date(dIn.setDate(dIn.getDate() + 1)).toISOString();
            const dataOut = new Date(dOut.setDate(dOut.getDate())).toISOString();
            return [
                {
                    field: 'dataIn',
                    operator: 'eq',
                    value: dataIn
                },
                {
                    field: 'dataOut',
                    operator: 'eq',
                    value: dataOut
                }
            ];
        },
        filters: {
            permanent: [
                {
                    field: 'seller][id]',
                    operator: 'eq',
                    value: store.id
                }
            ]
        },
        pagination: {
            mode: "server",
            pageSize: 99999999
        }
    });

    const [showTable, setShowTable] = useState(false);
    const [dati, setDati] = useState<any>([]);

    const confirm = () => {
        setShowTable(true);
        searchFormProps.form?.submit();
        let arrA: any = [];
        let arrV: any = [];
    }

    const [dataExport, setDataExport] = useState<any>([]);

    const exportDati = () => {
        setDataExport(datiGev);
    }

    useEffect(() => {
        if (tableQueryResult && tableQueryResult.data && tableQueryResult.data.data) {
            setDati(tableQueryResult?.data?.data);
        }
    }, [tableQueryResult]);

    const generaExcel = (dati: any) => {
        const datiExcel = [];

        // Aggiunge una riga di intestazione con il titolo
        datiExcel.push([`Report Turni dal ${dati.dataIn} al ${dati.dataOut}`]);
        datiExcel.push([]); // Riga vuota per separazione

        // Funzione per aggiungere una sezione
        const aggiungiSezione = (nomeSezione: any, dati: any) => {
            if (!dati || Object.keys(dati).length === 0) return;

            datiExcel.push([nomeSezione]); // Titolo della sezione
            datiExcel.push(["Descrizione", "Importo"]); // Intestazione

            Object.entries(dati).forEach(([key, value]) => {
                datiExcel.push([key, value]); // Aggiunge le righe
            });

            datiExcel.push([]); // Riga vuota per separazione
        };

        // Aggiunge tutte le sezioni
        aggiungiSezione("Entrate", {...dati.datiTurni.top, ...dati.datiTurni.entrate});
        aggiungiSezione("Uscite", dati.datiTurni.exit);
        aggiungiSezione("Contabile", dati.datiTurni.contabile);

        // Creazione del foglio Excel
        const ws = XLSX.utils.aoa_to_sheet(datiExcel);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Report");
        // Salva il file con il titolo del report
        const nomeFile = `Export_Turni_dal_${dati.dataIn}_al_${dati.dataOut}.xlsx`;
        XLSX.writeFile(wb, nomeFile);
    };

    return (
            <List title={'Report Turni'}>
                <Form {...searchFormProps} layout="horizontal">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item name={'dataIn'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'Dal...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name={'dataOut'}>
                            <DatePicker
                                style={{width: '100%'}}
                                format={'DD-MM-YYYY'}
                                placeholder={'al...'}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type={'primary'} style={{width: '100%'}} onClick={confirm}>Conferma</Button>
                    </Col>
                    <Col span={4}>
                            <Button type={'default'} style={{width: '100%'}} icon={<Icons.ExportOutlined />} onClick={() => generaExcel(dati)}>Export</Button>
                    </Col>
                </Row>
                </Form>
                <hr />
                <>
                    <div hidden={!showTable}>
                        <>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Title level={1} style={{color: 'white', textAlign: 'center'}}>Report Turni dal {dati && dayjs(dati.dataIn).format("DD-MM-YYYY")} al {dati && dayjs(dati.dataOut).format("DD-MM-YYYY")}</Title>
                                </Col>
                            </Row>
                            <br/>
                            <br/>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Title level={2} key={'entrate'}>ENTRATE</Title>
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['top']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}>{itemS}</Title>)
                                    })}
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['entrate']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}>{itemS}</Title>)
                                    })}
                                </Col>
                                <Col span={12}>
                                    <Title level={2} key={'importoE'}>IMPORTO</Title>
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['top']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}> € {Number(parseFloat(dati?.datiTurni?.['top'][itemS]).toFixed(2)).toLocaleString('de', {
                                            minimumFractionDigits: 2
                                        })}</Title>)
                                    })}
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['entrate']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}> € {Number(parseFloat(dati?.datiTurni?.['entrate'][itemS]).toFixed(2)).toLocaleString('de', {
                                            minimumFractionDigits: 2
                                        })}</Title>)
                                    })}
                                </Col>
                            </Row>
                            <hr />
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Title level={2} key={'uscite'}>USCITE</Title>
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['exit']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}>{itemS}</Title>)
                                    })}
                                </Col>
                                <Col span={12}>
                                    <Title level={2} key={'importoU'}>IMPORTO</Title>
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['exit']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}> € {Number(parseFloat(dati?.datiTurni?.['exit'][itemS]).toFixed(2)).toLocaleString('de', {
                                            minimumFractionDigits: 2
                                        })}</Title>)
                                    })}
                                </Col>
                            </Row>
                            <hr />
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Title level={2} key={'contabile'}>CONTABILE</Title>
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['contabile']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}>{itemS}</Title>)
                                    })}
                                </Col>
                                <Col span={12}>
                                    <Title level={2} key={'importoC'}>IMPORTO</Title>
                                    {dati && dati.datiTurni && Object.keys(dati?.datiTurni?.['contabile']).map((itemS: any) => {
                                        return (<Title level={5} style={{color: 'white'}}> € {Number(parseFloat(dati?.datiTurni?.['contabile'][itemS]).toFixed(2)).toLocaleString('de', {
                                            minimumFractionDigits: 2
                                        })}</Title>)
                                    })}
                                </Col>
                            </Row>
                        </>
                    </div>
                </>
            </List>
    );
};
