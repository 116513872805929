import {
    CrudFilters,
    IResourceComponentsProps, useCreate,
    useExport,
    useGetIdentity,
    useList,
    useNotification,
    useTranslate,
    useUpdate,
} from '@refinedev/core';

import {
    DateField,
    DeleteButton,
    List,
    ShowButton,
    TagField,
    useForm,
    useModal,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import {
    Button,
    Col,
    Divider,
    Form, Input,
    InputNumber, InputRef,
    MenuProps, message,
    Modal,
    Popconfirm, Popover,
    Row, Select,
    Space,
    Spin,
    Table,
    Tooltip,
    Typography, Upload, UploadProps,
} from 'antd';

import React, {useContext, useEffect, useRef, useState} from 'react';
import {IAlertRestock, ICausal, IDocument, ISaleMovement, IStock, ITurn, ITurnCost} from '../../interfaces';
import {StoreContext} from '../../contexts/StoreContext';
import dayjs from 'dayjs';
import "./style.css";
import {CSVLink} from 'react-csv';
import { useLocation, useNavigate } from "react-router-dom";
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';
import {API_URL} from '../../constants';
import {ColumnType} from 'antd/es/table';
import {FilterConfirmProps} from 'antd/es/table/interface';
const { Title } = Typography;
const { Dragger } = Upload;

function loadFile(url: any, callback: any) {
    PizZipUtils.getBinaryContent(url, callback);
}
export const TurnList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const state: any = useLocation();
    const {value, value2} = useContext(StoreContext);
    const [store, setStore] = value;
    const { data: userIdentity } = useGetIdentity<any>();
    const {tableProps, searchFormProps} = useTable<ITurn>({
        resource: "turns",
        meta: {populate: '*'},

        pagination: {
            pageSize: 15,
            mode: 'server'
        },
        onSearch: (params: any) => {
            const filters: CrudFilters = [];

            filters.push(
                {
                    operator: "or",
                    value: [
                        {
                            field: "product][barcodes][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][barcode_packages][code]",
                            operator: "eq",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][codice]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                        {
                            field: "product][category][description]",
                            operator: "contains",
                            value: (params?.description) ? params?.description : ''
                        },
                    ]
                },
                {
                    field: "product][category][id]",
                    operator: "contains",
                    value: (params?.category) ? params?.category : ''
                }
            );

            return filters;
        },
        filters: {
            permanent: [
                {
                    field: "seller][id]",
                    operator: 'eq',
                    value: store?.id
                }
            ]
        },

        sorters: {
            permanent: [
                {
                    field: 'createdAt',
                    order: 'desc'
                }
            ]
        }
    });

    const [causaliArrayUscite, setCausaliArrayUscite] = useState<any[]>([]);
    const [causaliArrayEntrate, setCausaliArrayEntrate] = useState<any[]>([]);
    const { show, close, modalProps } = useModal();
    const [turn, setTurn] = useState<ITurn>();
    const [causali, setCausali] = useState<any>();
    const [tabacchi, setTabacchi] = useState<number>(0);
    const [detailCategory, setDetailCategory] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [incassoReale, setIncassoReale] = useState<any>(0);
    const [importoEntrate, setImportoEntrate] = useState<any>(0);
    const [importoEntrateCausali, setImportoEntrateCausali] = useState<any>(0);
    const [importoUscite, setImportoUscite] = useState<number>(0);
    const [gratta, setGratta] = useState(0);
    const [scontrinato, setScontrinato] = useState(0);
    const [scontrinato22, setScontrinato22] = useState(0);
    const getMovement = useList<ISaleMovement>({
        resource: 'sale-movements',

        meta: {
            populate: "device, product, product.category, seller, turn"
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 100000000,
        },

        filters: [
            {
                field: "seller][id]",
                operator: 'eq',
                value: store?.id
            },
            {
                field: "turn][id]",
                operator: 'eq',
                value: turn?.id
            },
            {
                field: 'isClosed',
                operator: 'eq',
                value: true
            },
            {
                field: 'isDeleted',
                operator: 'eq',
                value: false
            }
        ],

        sorters: [
            {
                field: 'id',
                order: 'desc'
            }
        ]
    });

    const getDeletedMovement = useList<ISaleMovement>({
        resource: 'sale-movements',

        meta: {
            populate: "device, product, product.category, seller, turn"
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 100000000,
        },

        filters: [
            {
                field: "seller][id]",
                operator: 'eq',
                value: store?.id
            },
            {
                field: "turn][id]",
                operator: 'eq',
                value: turn?.id
            },
            {
                field: 'isDeleted',
                operator: 'eq',
                value: true
            }
        ],

        sorters: [
            {
                field: 'id',
                order: 'desc'
            }
        ]
    });

    const getAlertRestock = useList<IAlertRestock>({
        resource: 'alert-restocks',

        meta: {
            populate: "seller, turn, stock, stock.product, stock.product.category, turn.user"
        },

        queryOptions: {
            enabled: false
        },

        pagination: {
            pageSize: 100000000,
        },

        filters: [
            {
                field: "seller][id]",
                operator: 'eq',
                value: store?.id
            },
            {
                field: "turn][id]",
                operator: 'eq',
                value: turn?.id
            },
            {
                field: "isAlert",
                operator: 'eq',
                value: true
            }
        ],

        sorters: [
            {
                field: 'id',
                order: 'desc'
            }
        ]
    });

    const getCausali = useList<ICausal>({
        resource: 'causals',

        meta: {
            populate: '*'
        },
        pagination: {
            pageSize: 999,
        },
        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: "seller][id]",
                operator: 'eq',
                value: store?.id
            },
            {
                field: "isTurn",
                operator: 'eq',
                value: true
            }
        ],
        sorters: [
            {
                field: 'ordinamento',
                order: 'asc'
            }
        ]
    });

    const getDocumenti = useList<IDocument>({
        resource: 'documents',

        meta: {
            populate: '*'
        },
        pagination: {
            pageSize: 999,
        },
        queryOptions: {
            enabled: false
        },

        filters: [
            {
                field: "seller][id]",
                operator: 'eq',
                value: store?.id
            },
            {
                field: "turn][id]",
                operator: 'eq',
                value: turn?.id
            }
        ]
    });

    function groupAndSum(arr: any, groupKeys: any, sumKeys: any){
        return Object.values(
            arr.reduce((acc: any, curr: any)=>{
                const group = groupKeys.map((k: any) => curr[k]).join('-');
                acc[group] = acc[group] || Object.fromEntries(
                    groupKeys.map((k: any) => [k, curr[k]]).concat(sumKeys.map((k: any) => [k, 0])));
                sumKeys.forEach((k: any) => acc[group][k] += curr[k]);
                return acc;
            }, {})
        );
    }
    const showDetail = (record: any) => {
        // console.log(record);
        setExportDataTurn((current: any) => [...current, record]);
        setLoading(true);
        if (record && record.dati && record.dati.exit && Object.keys(record.dati.exit).length > 0) {
            for (let o of Object.keys(record.dati.exit)) {
                // console.log(o);
                uscite.setFieldValue(o, record.dati.exit[o])
            }
        } else {
            uscite.resetFields();
        }
        if (record && record.dati && record.dati.entrate && Object.keys(record.dati.entrate).length > 0) {
            for (let o of Object.keys(record.dati.entrate)) {
                // console.log(o);
                entrate.setFieldValue(o, record.dati.entrate[o])
            }
        } else {
            entrate.resetFields();
        }
        if (record && record.dati && record.dati.in && Object.keys(record.dati.in).length > 0) {
            setScontrinato22(record.dati.in.scontrinato22);
            setScontrinato(record.dati.in.scontrinato);
            setIncassoReale(record.dati.incassoReale);
        }
        show();
        setTurn(record);
        setTimeout(() => {
            getMovement.refetch().then( result => {
                // console.log(result);
                const groupCategory: any = [];
                if (result && result.data && result.data.data && result.data.data.length > 0) {
                    result.data?.data.map( x => {
                        groupCategory.push({category: 'Tabacchi', price: x.product.category.isTabacco ? x.price : 0})
                        groupCategory.push({category: 'Gratta e Vinci', price: x.product.category.isGeneric ? x.price : 0})
                        groupCategory.push({category: 'Accessori', price: x.product.category.isAccessory ? x.price : 0})
                        if (!x.product.category.isTabacco && !x.product.category.isGeneric && !x.product.category.isAccessory) {
                            groupCategory.push({category: 'Generico', price: x.price})
                        }
                        // groupCategory.push({category: 'Generico', price: (!x.product.category.isTabacco && !x.product.category.isGeneric) ? x.price : 0})
                        // if (x.product.category.isTabacco) {
                        //     groupCategory.push({category: 'Tabacchi', price: x.product.category.isTabacco ? x.price : 0})
                        // } else if (x.product.category.isGeneric) {
                        //     groupCategory.push({category: 'Gratta e Vinci', price: x.product.category.isGeneric ? x.price : 0})
                        // } else {
                        //     groupCategory.push({category: 'Generico', price: x.price ? x.price : 0})
                        // }
                    })

                    // GROUP BY CATEGORY
                    const tempGroup: any = groupCategory.reduce((r:any, a: any) => {
                        r[a.category] = [...(r[a.category] || []), a];
                        return r;
                    }, {})

                    let priceCategory = [];

                    for (let g in tempGroup) {
                        priceCategory.push({category: g, tot: parseFloat(Number(tempGroup[g].map((item: any) => item.price).reduce((prev: number, next: number) => prev + next)).toFixed(2))})
                    }
                    // console.log(priceCategory)
                    setDetailCategory(priceCategory);
                    // console.log(priceCategory);

                    setTabacchi(parseFloat(Number(result.data?.data?.map(item => item?.price).reduce((prev, next) => prev + next)).toFixed(2)));
                    setTimeout(() => {
                        const cauUscite: any = [];
                        const cauEntrate: any = [];
                        getCausali.refetch().then( causal => {
                            causal.data?.data.map( c => {
                                if (c.isUnLoad) {
                                    // console.log('UNLOAD')
                                    cauUscite.push({
                                        id: c.acronimo,
                                        amount: uscite.getFieldValue(c.acronimo) ? uscite.getFieldValue(c.acronimo) : 0,
                                        isUnLoad: true,
                                        isLoad: false
                                    })
                                }
                                if (c.isLoad) {
                                    // console.log('LOAD')
                                    cauEntrate.push({
                                        id: c.acronimo,
                                        amount: entrate.getFieldValue(c.acronimo) ? entrate.getFieldValue(c.acronimo) : 0,
                                        isLoad: true,
                                        isUnLoad: false
                                    })
                                }

                            })
                            setCausaliArrayUscite([...cauUscite]);
                            setCausaliArrayEntrate([...cauEntrate]);
                            // console.log(cau)
                            setCausali(causal.data?.data);
                            // console.log(causaliArray)
                            setTimeout(() => {
                                // console.log(tabacchi);
                                // console.log(causali);
                                setImportoEntrate(Number(parseFloat(tabacchi.toString()) + parseFloat(gratta.toString()) + parseFloat(scontrinato.toString()) + parseFloat(scontrinato22.toString()) + parseFloat(importoEntrateCausali.toString())).toFixed(2));
                                setLoading(false);
                            }, 700)
                        })
                        getDeletedMovements().then( r => {
                            setExportDataDeleteMovement(r);
                        });
                        getMovements().then( r => {
                            setExportData(r);
                        });
                    }, 500)
                } else {
                    setLoading(false);
                    open?.({
                        type: 'error',
                        message: 'Non ci sono vendite per il turno Selezionato',
                        description: 'Attenzione!',
                    });
                }
            })
        }, 500)

    }

    const onChangeGratta = (e: any) => {
        setGratta(e);
    }

    const onChangeScontrinato = (e: any) => {
        setScontrinato(e);
    }

    const onChangeScontrinato22 = (e: any) => {
        setScontrinato22(e);
    }

    const onChangeIncassoReale = (e: any) => {
        // console.log(e);
        setIncassoReale(Number(parseFloat(e.toString())));
    }

    const onChangeUscite = (e: any, acronimo: any) => {
        // console.log(e, incassoReale);
        const index = causaliArrayUscite.findIndex((x: any) => x.id === acronimo);
        causaliArrayUscite[index]['amount'] = e;
        // console.log(causaliArray[0]);
        setImportoUscite(causaliArrayUscite.reduce((sum: any, currentValue: any) => { return sum + parseFloat(currentValue.amount) }, 0));
        // console.log(importoUscite)
    }

    const onChangeEntrate = (e: any, acronimo: any) => {
        // console.log(e, incassoReale);
        const index = causaliArrayEntrate.findIndex((x: any) => x.id === acronimo);
        causaliArrayEntrate[index]['amount'] = e;
        // console.log(causaliArray[0]);
        setImportoEntrateCausali(causaliArrayEntrate.reduce((sum: any, currentValue: any) => { return sum + parseFloat(currentValue.amount) }, 0));
        // console.log(importoUscite)
    }

    useEffect(() => {
        // console.log(causaliArray);
        if (causaliArrayUscite && causaliArrayUscite.length > 0) {
            setImportoUscite(causaliArrayUscite.reduce((sum: any, currentValue: any) => { return (currentValue.isUnLoad) ? sum + parseFloat(currentValue.amount) : 0}, 0));

        }
        if (causaliArrayEntrate && causaliArrayEntrate.length > 0) {
            setImportoEntrateCausali(causaliArrayEntrate.reduce((sum: any, currentValue: any) => { return (currentValue.isLoad) ? sum + parseFloat(currentValue.amount) : 0}, 0));

        }
        setImportoEntrate(Number(parseFloat(tabacchi.toString()) + parseFloat(gratta.toString()) + parseFloat(scontrinato.toString()) + parseFloat(scontrinato22.toString()) + parseFloat(importoEntrateCausali.toString())).toFixed(2));
        // console.log(incassoReale)
        // console.log(importoEntrate)
    })

    useEffect(() => {
        if (state && state?.state?.isOpen) {
            showDetail(state?.state?.record);
        }
    }, [])

    const [exportData, setExportData] = useState([]);
    const [exportDataMovement, setExportDataMovement] = useState([]);
    const [exportDataDeleteMovement, setExportDataDeleteMovement] = useState([]);
    const [exportDataRestock, setExportDataRestock] = useState([]);
    const [exportDataTurn, setExportDataTurn] = useState<any>([]);
    const { show: showDetailMovement, close: closeDetailMovement, modalProps: modalDetailMovement } = useModal();
    const { show: showModalDeletedMovement, close: closeModalDeletedMovement, modalProps: modalDeletedMovement } = useModal();
    const { show: showModalAlertRestock, close: closeModalAlertRestock, modalProps: modalAlertRestock } = useModal();
    const { show: showModalSnapshotStock, close: closeModalSnapshotStock, modalProps: modalModalSnapshotStock } = useModal();
    const { show: showModalDocument, close: closeModalDocument, modalProps: modalModalDocument } = useModal();
    const [isLoading, setIsLoading] = useState(false);

    function getMovements(): Promise<any> {
        return new Promise( resolve => {
            getMovement.refetch().then( (res: any) => {
                // console.log("DATA MOVEMENT: ", res);
                resolve(res.data?.data);
            });
        })

    }

    function getDeletedMovements(): Promise<any> {
        return new Promise( resolve => {
            getDeletedMovement.refetch().then( (res: any) => {
                // console.log("DATA DELETE MOVEMENT: ", res);
                resolve(res.data?.data);
            });
        })

    }
    function getAlertRestocks(): Promise<any> {
        return new Promise( resolve => {
            getAlertRestock.refetch().then( (res: any) => {
                // console.log("DATA ALERT RESTOCK: ", res);
                resolve(res.data?.data);
            });
        })

    }

    const [loadingM, setLoadingM] = useState(false);
    const [loadingD, setLoadingD] = useState(false);
    const [loadingR, setLoadingR] = useState(false);
    const [loadingS, setLoadingS] = useState(false);
    const [loadingF, setLoadingF] = useState(false);
    const showMovement = (record: any) => {
        setTurn(record);
        setLoadingM(true);
        setTimeout(() => {
            getMovements().then( (res: any) => {
                setExportData(res);
                setTimeout(() => {
                    setLoadingM(false);
                    showDetailMovement();
                }, 400)
            });
        }, 500)
    }

    const showDeletedMovement = (record: any) => {
        setTurn(record);
        setLoadingD(true);
        setTimeout(() => {
            getDeletedMovements().then( (res: any) => {
                setExportDataDeleteMovement(res);
                setTimeout(() => {
                    setLoadingD(false);
                    showModalDeletedMovement();
                }, 400)
            });
        }, 500)
    }

    const showAlertRestock = (record: any) => {
        setTurn(record);
        setLoadingR(true);
        setTimeout(() => {
            getAlertRestocks().then( (res: any) => {
                setExportDataRestock(res);
                setTimeout(() => {
                    setLoadingR(false);
                    showModalAlertRestock();
                }, 400)
            });
        }, 500)
    }

    const showSnapshotStock = (record: any) => {
        setTurn(record);
        setLoadingS(true);
        setTimeout(() => {
                    setLoadingS(false);
                    showModalSnapshotStock();
        }, 500)
    }

    const showDocument = (record: any) => {
        setTurn(record);
        setLoadingF(true);
        setTimeout(() => {
            getDocumenti.refetch().then((res: any) => {
                // console.log(res);
            })
            setLoadingF(false);
            showModalDocument();
        }, 500)
    }

    const exportMovement = (event: any, done: any) => {
        setTimeout(() => {
                    setIsLoading(false);
                    done(true);
        }, 500)
    }
    const exportTurn = () => {
        // console.log("EXPORT: ", turn);
        // console.log("CAUSALI: ", causali);
        // console.log("CATEGORY: ", detailCategory);
        const dati: any = turn?.dati;
        const entrate: any = [];
        const uscite: any = [];
        for (let ent of dati.detailCategory) {
            entrate.push({
                descrizione: ent.category,
                importo: Number(ent.tot).toFixed(2)
            })
        }
        for (let ent of Object.keys(dati.entrate)) {
            entrate.push({
                descrizione: causali.find((x: any) => x.acronimo === ent).description,
                importo: Number(dati.entrate[ent]).toFixed(2)
            })
        }
        entrate.push({
                descrizione: 'Scontrinato',
                importo: Number(dati.in['scontrinato']).toFixed(2)
            },
            {
                descrizione: 'Scontrinato 22%',
                importo: Number(dati.in['scontrinato22']).toFixed(2)
            })
        for (let ent of Object.keys(dati.exit)) {
            uscite.push({
                descrizione: causali.find((x: any) => x.acronimo === ent).description,
                importo: Number(dati.exit[ent]).toFixed(2)
            })
        }
        // console.log(entrate);
        // console.log(uscite);
        loadFile(
            '/template/TURN.docx',
            function (error: any, content: any) {
                if (error) {
                    throw error;
                }
                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                });
                        doc.setData({
                            dataIn: dayjs(turn?.dataIn).format('DD-MM-YYYY HH:mm'),
                            dataOut: dayjs(turn?.dataOut).format('DD-MM-YYYY HH:mm'),
                            user: turn?.user?.name + ' ' + turn?.user?.name,
                            isDeleted: (exportDataDeleteMovement && exportDataDeleteMovement?.length > 0) ? 'Sono Presenti Movimenti Cancellati' : 'NO',
                            isRestock: (exportDataRestock && exportDataRestock?.length > 0) ? 'Sono Presenti Riassortimenti' : 'NO',
                            in: entrate,
                            exit: uscite,
                            importoEntrate: Number(dati.importoEntrate).toFixed(2),
                            importoUscite: Number(dati.importoUscite).toFixed(2),
                            incassoContabile: Number(dati.incassoContabile).toFixed(2),
                            incassoReale: Number(dati.incassoReale).toFixed(2),
                            differenza: Number(dati.differenza).toFixed(2)
                        });
                        try {
                            doc.render();
                        } catch (error) {
                            throw error;
                        }
                        let out = doc.getZip().generate({
                            type: 'blob',
                            mimeType:
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                        }); //Output the document using Data-URI
                        saveAs(out, 'TURNO_' + dayjs(turn?.dataIn).format('DD_MM_YYYY') + '.docx');
            }
        );
    }

    const {open} = useNotification();
    const {form: uscite} = useForm();
    const {form: entrate} = useForm();
    const updateTurn = useUpdate<ITurn>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const showPopconfirm = () => {
        setOpenConfirm(true);
    };
    const handleCancel = () => {
        // console.log('Clicked cancel button');
        setOpenConfirm(false);
        window.location.reload();
    };

    const onCheck = async () => {
        try {
            // const values = await form.validateFields();
            // console.log('Success:', values);
            // console.log("scontrinato: ", scontrinato);
            // console.log("scontrinato22: ", scontrinato22);
            // console.log("incasso reale: ", incassoReale);
            if (scontrinato && scontrinato22 && (incassoReale >= 0)) {
                showPopconfirm();
            } else {
                open?.({
                    type: 'error',
                    message: 'Devi compilare necessariamente tutti i campi...',
                    description: 'Attenzione!',
                });
            }
        } catch (errorInfo) {
            console.log('Failed:', errorInfo);
        }
    };

        const conferma = () => {
            setOpenConfirm(false);
            // console.log(form.getFieldsValue());
                updateTurn.mutateAsync({
                    resource: 'turns',
                    successNotification: false,
                    id: Number(turn?.id),
                    values: {
                        isConfirmed: true,
                        dati: {
                            detailCategory,
                            in: {
                                tabacchi: detailCategory[0]?.tot,
                                gev: detailCategory[1]?.tot,
                                acs: detailCategory[2]?.tot,
                                scontrinato: Number(parseFloat(scontrinato.toString())).toFixed(2),
                                scontrinato22: Number(parseFloat(scontrinato22.toString())).toFixed(2),
                            },
                            entrate: entrate.getFieldsValue(),
                            incassoReale: Number(parseFloat(incassoReale.toString())).toFixed(2),
                            importoEntrate: Number(parseFloat(importoEntrate.toString())).toFixed(2),
                            importoUscite: Number(parseFloat(importoUscite.toString())).toFixed(2),
                            incassoContabile: Number(parseFloat(importoEntrate) - importoUscite).toFixed(2),
                            differenza: Number(incassoReale - (importoEntrate - importoUscite)).toFixed(2),
                            isRestock: (exportDataRestock?.length > 0) ? 'SI' : 'NO',
                            isDeleted: (exportDataDeleteMovement?.length > 0)? 'SI' : 'NO',
                            exit: uscite.getFieldsValue()
                        }
                    }
                }).then( r => {
                    close();
                    open?.({
                        type: 'success',
                        message: 'Modulo Chiuso Correttamente...',
                        description: 'Info!',
                    });
                })

    }

    const createDocumento = useCreate<IDocument>();
    const props: UploadProps = {
        name: 'files',
        multiple: true,
        action: API_URL + '/api/upload',
        onChange(info) {
            // console.log(info.file.response);
            const { status } = info.file;
            if (status !== 'uploading') {
                // formProps.form?.setFieldValue("document", info.file.response);
            }
            if (status === 'done') {
                createDocumento.mutateAsync({
                    resource: 'documents',
                    values: {
                        description: info.file.response[0].name,
                        file: info.file.response[0].id,
                        seller: store.id,
                        turn: turn?.id,
                        user: userIdentity.id
                    }
                }).then(r => {
                    getDocumenti.refetch();
                    message.loading(`${info.file.name} file uploaded successfully.`);
                })
            } else if (status === 'error') {
                message.loading(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };
    // console.log(exportDataMovement);
    // console.log(exportDataRestock);
    // console.log(exportDataDeleteMovement);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);
    interface DataType {
        key: string;
        description: string;
        product: string;
    }
    type DataIndex = keyof DataType;

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<Icons.SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <Icons.SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            text
    });

    return (
        <List
            title={translate('pages.turn.title', 'Turni')}
            headerProps={{extra: []}
            }
        >
            <Table size="small" {...tableProps} rowKey="id"
                   onRow={(record) => {
                       return {
                           onClick: () => {
                               // console.log("turn", record);
                           },
                       };
                   }}
                   sticky={true}
                   // scroll={{y: window.innerHeight - 293 + 'px'}}
                   rowClassName={(record, index) => (record.isConfirmed ? "green" : "red")}
            >
                {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                <Table.Column
                    dataIndex="user"
                    key="user"
                    title={translate('pages.category.user', 'Utente')}
                    responsive={['xs', 'md', 'lg', 'xl']}
                    render={(value, record) => {
                        // <TagField value={value.name + ' ' + value.surname} />
                        return (<>
                            <TagField value={value?.name + ' ' + value?.surname} />
                        </>)
                    }
                }
                />
                <Table.Column
                    dataIndex="dataIn"
                    key="dataIn"
                    title={translate('pages.category.dataIn', 'Data Apertura')}
                    responsive={['md', 'lg', 'xl']}
                    render={(value) => <DateField value={value} format={'DD-MM-YYYY HH:mm'} />}
                />
                <Table.Column
                    dataIndex="dataOut"
                    key="dataOut"
                    title={translate('pages.category.dataOut', 'Data Chiusura')}
                    responsive={['md', 'lg', 'xl']}
                    render={(value) => <DateField value={value} format={'DD-MM-YYYY HH:mm'} />}
                />
                <Table.Column<{ id: string }>
                    title={translate('pages.docType.action', 'Azioni')}
                    dataIndex="actions"
                    responsive={['md', 'lg', 'xl']}
                    key="actions"
                    render={(_, record) => (
                        <Space>
                            <Tooltip placement="top" title={'Riepilogo Turno'}>
                            <ShowButton
                                hideText
                                size="small"
                                recordItemId={record.id}
                                onClick={() => showDetail(record)}
                            />
                            </Tooltip>
                            <Tooltip placement="top" title={'Elenco Vendite Turno'}>
                            <Button
                                loading={loadingM && (turn?.id === Number(record.id))}
                                size={'small'}
                                icon={<Icons.OrderedListOutlined />}
                                onClick={() => showMovement(record)}
                            >
                            </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={'Elenco Vendite Cancellate Turno'}>
                            <Button
                                style={{
                                    color: (turn?.id === Number(record?.id) && exportDataDeleteMovement?.length > 0) ? 'red' : 'whitesmoke'
                                }}
                                loading={loadingD && (turn?.id === Number(record.id))}
                                size={'small'}
                                icon={<Icons.DeleteColumnOutlined />}
                                onClick={() => showDeletedMovement(record)}
                            >
                            </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={'Elenco Riassortimento Anticipato'}>
                                <Button
                                    style={{
                                        color: (turn?.id === Number(record?.id) && exportDataRestock?.length > 0) ? 'red' : 'whitesmoke'
                                    }}
                                    loading={loadingR && (turn?.id === Number(record.id))}
                                    size={'small'}
                                    icon={<Icons.FileJpgOutlined />}
                                    onClick={() => showAlertRestock(record)}
                                >
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={'Inventario'}>
                                <Button
                                    loading={loadingS && (turn?.id === Number(record.id))}
                                    size={'small'}
                                    icon={<Icons.FolderOutlined />}
                                    onClick={() => showSnapshotStock(record)}
                                >
                                </Button>
                            </Tooltip>
                            <Tooltip placement="top" title={'Documenti'}>
                                <Button
                                    loading={loadingS && (turn?.id === Number(record.id))}
                                    size={'small'}
                                    icon={<Icons.UploadOutlined />}
                                    onClick={() => showDocument(record)}
                                >
                                </Button>
                            </Tooltip>
                            <DeleteButton hidden={true}
                                hideText
                                size="small"
                                recordItemId={record.id}
                            />
                        </Space>
                    )}
                />
            </Table>
            <Modal {...modalDetailMovement} width={'60%'} cancelText={'Annulla'} okText={'Esporta'} footer={<>
                <CSVLink
                    data={exportData}
                    separator={';'}
                    asyncOnClick={true}
                    filename={'Export_Dettaglio_Turni.csv'}
                    onClick={exportMovement}
                >
                    <Button icon={<Icons.FileExcelOutlined />}></Button>
                </CSVLink>
            </>}>
                <Table dataSource={exportData}
                       key={'id'}
                       size={'small'}
                       sticky={false}
                       scroll={{y: window.innerHeight - 330 + 'px'}}
                       rowClassName={(record, index) => "green"}
                       pagination={false}>
                    <Table.Column
                        dataIndex={'description'}
                        key={'description'}
                        title={'Articolo'}
                        {...getColumnSearchProps('description')}
                    />
                    <Table.Column
                        dataIndex={'product'}
                        key={'category'}
                        title={'Categoria'}
                        filters={[
                            {
                                text: 'Tabacchi',
                                value: 'isTabacco',
                            },
                            {
                                text: 'Gratta e Vinci',
                                value: 'isGeneric',
                            }
                        ]}
                        onFilter={(value: any, record: any) => {
                            return record?.product?.category[value];
                        }}
                        render={(value) => (
                            value.category?.description
                        )}
                    />
                    <Table.Column
                        dataIndex={'price'}
                        key={'price'}
                        title={'Prezzo'}
                        render={(value) => (
                            value.toFixed(2)
                        )}
                    />
                    <Table.Column
                        dataIndex={'createdAt'}
                        key={'createdAt'}
                        title={'Data'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                </Table>
            </Modal>
            <Modal {...modalDeletedMovement} width={'60%'} cancelText={'Annulla'} okText={'Esporta'} footer={<>
                <CSVLink
                    data={exportDataDeleteMovement}
                    separator={';'}
                    asyncOnClick={true}
                    filename={'Export_Dettaglio_Movimenti_Cancellati.csv'}
                    onClick={exportMovement}
                >
                    <Button icon={<Icons.FileExcelOutlined />}></Button>
                </CSVLink>
            </>}>
                <Table dataSource={exportDataDeleteMovement}
                       size={'small'}
                       sticky={false}
                       scroll={{y: window.innerHeight - 330 + 'px'}}
                       rowClassName={(record, index) => "deleted"}
                       pagination={false}>
                    <Table.Column
                        dataIndex={'description'}
                        key={'description'}
                        title={'Articolo'}
                    />
                    <Table.Column
                        dataIndex={'product'}
                        key={'category'}
                        title={'Categoria'}
                        render={(value) => (
                            value.category?.description
                        )}
                    />
                    <Table.Column
                        dataIndex={'price'}
                        key={'price'}
                        title={'Prezzo'}
                        render={(value) => (
                            value.toFixed(2)
                        )}
                    />
                    <Table.Column
                        dataIndex={'createdAt'}
                        key={'createdAt'}
                        title={'Data'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                </Table>
            </Modal>
            <Modal {...modalAlertRestock} width={'60%'}
                   okText={'Chiudi'}
                   cancelText={'Annulla'}
                   onOk={closeModalAlertRestock}>
                <Table dataSource={exportDataRestock}
                       size={'small'}
                       sticky={false}
                       scroll={{y: window.innerHeight - 330 + 'px'}}
                       rowClassName={(record, index) => "red"}
                       pagination={false}>
                    <Table.Column
                        dataIndex={'turn'}
                        key={'turn'}
                        title={'User'}
                        render={(value) => (
                            value.user?.name + ' ' + value.user?.surname
                        )}
                    />
                    <Table.Column
                        dataIndex={'stock'}
                        key={'stock'}
                        title={'Articolo'}
                        render={(value) => (
                            value.product?.codice
                        )}
                    />
                    <Table.Column
                        dataIndex={'stock'}
                        key={'stock.description'}
                        title={'Descrizione'}
                        render={(value) => (
                            value.product?.description
                        )}
                    />
                    <Table.Column
                        dataIndex={'position'}
                        key={'position'}
                        title={'Posizione'}
                        render={(value) => (
                            <TagField value={value} style={{ color: 'red'}}/>
                        )}
                    />
                    <Table.Column
                        dataIndex={'description'}
                        key={'description'}
                        title={'Motivo'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'note'}
                        key={'note'}
                        title={'Note'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'createdAt'}
                        key={'createdAt'}
                        title={'Data'}
                        render={(value) => (
                            <DateField value={value} format={'DD-MM-YYYY - HH:mm:ss'} />
                        )}
                    />
                </Table>
            </Modal>
            <Modal {...modalModalSnapshotStock} width={'60%'}
                   okText={'Chiudi'}
                   cancelText={'Annulla'}
                   onOk={closeModalSnapshotStock}>
                <Table dataSource={turn?.snapshotStock?.priceCategoryEspositore}
                       title={data => (<Title level={5}>Espositore</Title> )}
                       size={'small'}
                       rowKey="id"
                       sticky={false}
                       pagination={false}>
                    <Table.Column
                        dataIndex={'category'}
                        key={Math.random()}
                        title={'Categoria'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'qta'}
                        key={'qta'}
                        title={'Qta'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'tot'}
                        key={'tot'}
                        title={'Totale'}
                        render={(value) => (
                            '€ ' + parseFloat(value).toLocaleString('de', {
                                minimumFractionDigits: 2
                            })
                        )}
                    />
                </Table>
                <Table dataSource={turn?.snapshotStock?.priceCategoryMagazzino}
                       title={data => (<Title level={5}>Magazzino</Title> )}
                       size={'small'}
                       sticky={false}
                       rowKey="id"
                       pagination={false}>
                    <Table.Column
                        dataIndex={'category'}
                        key={'category'}
                        title={'Categoria'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'qta'}
                        key={'qta'}
                        title={'Qta'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'qtaP'}
                        key={'qtaP'}
                        title={'Qta P'}
                        render={(value) => (
                            value
                        )}
                    />
                    <Table.Column
                        dataIndex={'tot'}
                        key={'tot'}
                        title={'Totale'}
                        render={(value) => (
                            '€ ' + parseFloat(value).toLocaleString('de', {
                                minimumFractionDigits: 2
                            })
                        )}
                    />
                </Table>
            </Modal>
            <Modal {...modalModalDocument} width={'60%'}
                   okText={'Chiudi'}
                   cancelText={'Annulla'}
                   onOk={closeModalDocument}>
                <br />
                <hr />
                <Row gutter={24}>
                    <Col span={24}>
                        <Dragger {...props}
                                 showUploadList={false}
                                 style={{
                                     background: '#e8e268',
                                     border: 'none'
                                 }}
                                 multiple={false}
                        >
                            {/*<p className="ant-upload-text">Click or drag file to this area to upload</p>*/}
                            <p className="ant-upload-hint" style={{fontSize: 14, color: 'black'}}>
                                Upload Documenti!
                            </p>
                        </Dragger>
                    </Col>
                </Row>
                <hr />
                <Row gutter={24}>
                    <Col span={24}>
                        <Table dataSource={getDocumenti.data?.data}
                               size={'small'}
                               sticky={false}
                               pagination={false}>
                            <Table.Column
                                dataIndex={'description'}
                                key={'description'}
                                title={'Descrizione'}
                            />
                            <Table.Column
                                dataIndex={'createdAt'}
                                key={'createdAt'}
                                title={'Data'}
                                render={(value) => (
                                    <DateField value={value} format={'DD-MM-YYYY HH:mm'} />
                                )}
                            />
                            <Table.Column
                                dataIndex={'file'}
                                key={'file'}
                                title={'File'}
                                render={(value) => {
                                    // console.log(value)
                                    return (<Button type={'link'} size={'large'} icon={<Icons.DownloadOutlined />} href={API_URL + value.url} target={'_blank'} />)
                                }}
                            />
                        </Table>
                    </Col>
                </Row>
            </Modal>
            <Modal {...modalProps} width={900} cancelText={'Annulla'} okText={'Conferma'}
                   footer={
                <>
                    <Space>
                        <Button icon={<Icons.ExportOutlined  />} htmlType={'button'} onClick={() => exportTurn()}>Stampa Turno</Button>
                        <Button danger={true} type="primary" onClick={close}>
                            {turn?.isConfirmed ? 'Chiudi' : 'Annulla'}
                        </Button>
                        <Popconfirm
                            title="Attenzione"
                            open={openConfirm}
                            description="Sei Sicuro di voler confermare i dati inseriti?
                        Una volta confermato non potrai più modificare i dati!"
                            onConfirm={conferma}
                            cancelText={'No'}
                            okText={'Si! Sono Sicuro'}
                            onCancel={handleCancel}
                        >
                            <Button type="primary" onClick={onCheck} hidden={turn?.isConfirmed}>
                                Conferma
                            </Button>
                        </Popconfirm>
                    </Space>
                </>

            }
            >
                <Space direction="vertical"  style={{ width: '100%' }}>
                <Spin spinning={loading} tip="Loading" size={'large'} >
                    <Title level={4}>Utente: {turn?.user?.name} - Apertura: { dayjs(turn?.dataIn).format('DD-MM-YYYY HH:mm')} - Chiusura: { dayjs(turn?.dataOut).format('DD-MM-YYYY HH:mm')} </Title>
                    <Title level={5} style={{ color: 'red'}}>
                        {(exportDataDeleteMovement && exportDataDeleteMovement?.length > 0) ? 'Sono Presenti Movimenti Cancellati' : ''}
                        {(exportDataRestock && exportDataRestock?.length > 0) ? ' --- ' : ''}
                        {(exportDataRestock && exportDataRestock?.length > 0) ? 'Sono Presenti Alert Riassortimento' : ''}
                    </Title>
                    <Divider />

                        <Row gutter={24}>
                            <Col span={12}>
                                <Title level={4} >Entrate</Title>
                            </Col>
                            <Col span={12}>
                                <Title level={4} >Importo</Title>
                            </Col>
                        </Row>
                        <Divider />
                        { detailCategory.map((d: any, index: number) => {
                            return(
                                    <Row gutter={24}>
                                        <Col span={8}>
                                            {d.category}
                                        </Col>
                                        <Col span={16}>
                                            <InputNumber
                                                key={Math.random()}
                                                readOnly={true}
                                                style={{width: '100%', appearance: 'textfield', backgroundColor: index % 2 ? '#222222' : ''}}
                                                decimalSeparator = {','}
                                                value={d.tot.toString()}
                                                formatter={(tot) => `${Number(parseFloat(Number(tot).toString()).toFixed(2)).toLocaleString('de', {
                                                    minimumFractionDigits: 2
                                                })}`}
                                            />
                                        </Col>
                                    </Row>
                            )
                        })}
                        <Row gutter={24}>
                            <Col span={8}>
                                Scontrinato
                            </Col>
                            <Col span={16}>
                                <InputNumber
                                    precision={2}
                                    controls={false}
                                    readOnly={turn?.isConfirmed}
                                    decimalSeparator = {','}
                                    style={{width: '100%', appearance: 'textfield', backgroundColor: '#222222'}}
                                    onInput={onChangeScontrinato}
                                    value={turn?.dati?.in?.scontrinato}
                                    // formatter={(scontrinato) => `${parseFloat(Number(scontrinato).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    // parser={(scontrinato) => parseFloat(Number(scontrinato).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                Scontrinato 22%
                            </Col>
                            <Col span={16}>
                                <InputNumber
                                    precision={2}
                                    controls={false}
                                    readOnly={turn?.isConfirmed}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    decimalSeparator = {','}
                                    onInput={onChangeScontrinato22}
                                    value={turn?.dati?.in?.scontrinato22}
                                    // formatter={(scontrinato22) => `${parseFloat(Number(scontrinato22).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    // parser={(scontrinato22) => parseFloat(Number(scontrinato22).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Col>
                        </Row>
                    <Form form={entrate}>
                        { causali && causali.map( (x: any, i: any) =>
                          x.isLoad && x.isTurn &&
                            <Row gutter={24}>
                                <Col span={8}>
                                    {x.description}
                                </Col>
                                <Col span={16}>
                                        <Form.Item
                                            style={{marginBottom:  0}}
                                            name={x.acronimo}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Campo Obbligatorio'
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                controls={false}
                                                readOnly={turn?.isConfirmed}
                                                style={{width: '100%', appearance: 'textfield', backgroundColor: i % 2 ? '' : '#222222'}}
                                                precision={2}
                                                decimalSeparator= {','}
                                                onChange={(e) => onChangeEntrate(e, x.acronimo)}
                                                // formatter={(value) => `${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, '.')}
                                                // parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                            />
                                        </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </Form>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Title level={3} >Importo Entrate</Title>
                            </Col>
                            <Col span={12}>
                                <Title level={3} >{Number(parseFloat(importoEntrate).toFixed(2)).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                })} €</Title>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={24}>
                            <Col span={12}>
                                <Title level={4} >Uscite</Title>
                            </Col>
                            <Col span={12}>
                                <Title level={4} >Importo</Title>
                            </Col>
                        </Row>
                        <Divider />
                    <Form form={uscite}>
                        { causali && causali.map( (x: any, i: any) =>
                            x.isUnLoad && x.isTurn &&
                            <Row gutter={24}>
                                <Col span={8}>
                                    {x.description}
                                </Col>
                                <Col span={16}>
                                    <Form.Item
                                        style={{marginBottom:  0}}
                                        name={x.acronimo}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Campo Obbligatorio'
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            controls={false}
                                            readOnly={turn?.isConfirmed}
                                            style={{width: '100%', appearance: 'textfield', backgroundColor: i % 2 ? '#222222' : ''}}
                                            precision={2}
                                            decimalSeparator= {','}
                                            onChange={(e) => onChangeUscite(e, x.acronimo)}
                                            // formatter={(value) => `${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, '.')}
                                            // parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        )}
                    </Form>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Title level={3} >Importo Uscite</Title>
                            </Col>
                            <Col span={12}>
                                <Title level={3} >{Number(importoUscite.toFixed(2)).toLocaleString('de', {
                                    minimumFractionDigits: 2
                                })} €</Title>
                            </Col>
                        </Row>
                        <Divider />
                        <Row gutter={24}>
                            <Col span={8}>
                                Incasso Contabile
                            </Col>
                            <Col span={16}>
                                <InputNumber readOnly={true}
                                             precision={2}
                                             controls={false}
                                             style={{width: '100%', appearance: 'textfield', backgroundColor: '#222222'}}
                                             decimalSeparator = {','}
                                             value={(parseFloat(importoEntrate) - importoUscite).toFixed(2)}
                                             formatter={(tabacchi) => `${parseFloat(Number(tabacchi).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, ',')}
                                    // parser={(tabacchi) => parseFloat(Number(tabacchi).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                Incasso Reale
                            </Col>
                            <Col span={16}>
                                <InputNumber
                                    name={'incassoReale'}
                                    style={{width: '100%', appearance: 'textfield'}}
                                    controls={false}
                                    precision={2}
                                    decimalSeparator = {','}
                                    readOnly={turn?.isConfirmed}
                                    onInput={onChangeIncassoReale}
                                    value={turn?.dati?.incassoReale}
                                    // formatter={(value) => `${parseFloat(Number(value).toString()).toFixed(2)}`.replace(/\$\s?|(\.)/g, '.')}
                                    // parser={(value) => parseFloat(Number(value).toString()).toFixed(2).replace('.', '').replace(/,/g, '.')}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <Title level={3} >Differenza</Title>
                            </Col>
                            <Col span={12}>
                                <Title level={3} >{
                                    Number(incassoReale - (importoEntrate - importoUscite)).toFixed(2)
                                } €</Title>
                            </Col>
                        </Row>

                </Spin>
                </Space>
            </Modal>
        </List>
    );
};
