import { IResourceComponentsProps, useExport, useTranslate } from "@refinedev/core";

import {
    BooleanField,
    CreateButton,
    DeleteButton,
    EditButton,
    ExportButton,
    List,
    TagField,
    useTable,
} from "@refinedev/antd";

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from "@ant-design/icons";

import { Space, Table } from "antd";
import React from "react";
import {IDevice} from '../../interfaces';


export const DeviceList: React.FC<IResourceComponentsProps> = () => {
    const translate = useTranslate();
    const {tableProps} = useTable<IDevice>({
        resource: "devices",
        meta: {populate: '*'}
    });

    const {triggerExport, isLoading: exportLoading} = useExport<IDevice>();

    return (
            <List
                title={translate('pages.device.title', 'Devices')}
                headerProps={{
                    extra: [
                        <CreateButton/>,
                        <ExportButton
                            onClick={triggerExport}
                            loading={exportLoading}
                        />
                    ],
                }}
            >
                <Table size="small" {...tableProps} rowKey="id"
                       onRow={(record) => {
                           return {
                               onClick: () => {
                                   // console.log("device", record);
                               },
                           };
                       }}
                       sticky={false}

                       pagination={{
                           ...tableProps.pagination,
                           showSizeChanger: true,
                       }}>
                    {/*<Table.Column dataIndex="id" key="id" title="ID"/>*/}
                    <Table.Column
                        dataIndex="description"
                        key="description"
                        title={translate('pages.device.description', 'Description')}
                    />
                    <Table.Column
                        dataIndex="deviceNumber"
                        key="deviceNumber"
                        title={translate('pages.device.deviceNumber', 'Numero Dispositivo')}
                        render={(value, record) => (
                            <TagField color={'green'} value={value} />
                        )}
                    />
                    <Table.Column
                        dataIndex="serial"
                        key="serial"
                        title={translate('pages.device.serial', 'Serial')}
                    />
                    <Table.Column
                        dataIndex="ip"
                        key="ip"
                        title={translate('pages.device.ip', 'Indirizzo IP')}
                    />
                    <Table.Column
                        dataIndex="deviceNumber"
                        key="deviceNumber"
                        title={translate('pages.device.deviceNumber', 'Prog. Device')}
                    />
                    <Table.Column
                        dataIndex="deviceCount"
                        key="deviceCount"
                        title={translate('pages.device.deviceCount', 'N. Moduli')}
                    />
                    <Table.Column
                        dataIndex="versioneSoftware"
                        key="versioneSoftware"
                        title={translate('pages.device.versioneSoftware', 'Ver. Soft.')}
                    />
                    <Table.Column
                        dataIndex="device_type"
                        key="device_type"
                        title={translate('pages.device.ip', 'Type')}
                        render={(value) => <TagField value={value?.description} color={'green'}/>}
                    />
                    <Table.Column
                        dataIndex="isUnicoTurno"
                        key="isUnicoTurno"
                        title={translate('pages.device.isUnicoTurno', 'Unico Turno')}
                        render={(value) => <TagField value={value ? "SI" : "NO"} color={'yellow'}/>}
                    />
                    <Table.Column
                        dataIndex="magazzini"
                        key="magazzini"
                        title={translate('pages.device.magazzini', 'Magazzino')}
                        render={(value) => <TagField value={value?.description} color={'green'}/>}
                    />
                    <Table.Column<{ id: string }>
                        title={translate('pages.device.action', 'Action')}
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                                <DeleteButton
                                    mutationMode={'undoable'}
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                />
                            </Space>
                        )}
                    />

                </Table>
            </List>
    );
};
